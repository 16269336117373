import * as React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckIcon from "@mui/icons-material/Check";
import Tooltip from "@mui/material/Tooltip";
import { TextField } from "@mui/material";
import Can from "./Can";
import { usePermissionContext } from "../../contexts/ProviderProvider";
import useCanUser from "../../hooks/useCanUser";

export const TextModifier = ({
  label,
  original,
  value,
  setValue,
  style,
  permissions,
  required,
  borderColor,
  InputProps,
  disabled
}) => {
  const filterOriginal = (newValue) => {
    if (newValue !== original) {
      setValue(newValue);
    } else {
      setValue(undefined);
    }
  };
  const { actions } = usePermissionContext();
  const canUserAlter = useCanUser(actions.ALTER, [permissions]);
  const canDisabled = permissions ? !canUserAlter : false;

  return (
    <>
      <TextField
        required={required != undefined ? required : false}
        label={label}
        value={value != undefined ? value : original}
        onChange={(e) => filterOriginal(e.target.value)}
        style={style}
        sx={{ width: "75%" }}
        disabled={disabled || canDisabled}
        borderColor={borderColor}
        InputProps={InputProps}
      />
      {(value || value == "") && value !== original && (
        <>
          {!disabled &&
            <Tooltip title="Reset">
              <RefreshIcon
                onClick={() => setValue(undefined)}
                style={{
                  color: "#6495ED",
                  marginTop: "10px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>}

        </>
      )}
    </>
  );
};
