import { useEffect, useState } from "react";
import {
  useSchoolContext,
  useUserContext,
  usePermissionContext
} from "../../../contexts/ProviderProvider";
import ProfileHeader from "./ProfileHeader";
import { LinearProgress, Typography } from "@mui/material";
import {
  getAthleteUserById,
  getMerchRosterById,
} from "../../../API/athleteUser";
import { getCollectionAndProductsByRosterId } from "../../../API/collection";
import { getAllReferralByPhone, getAllSportsBySchool, updateUser } from "../../../API/roster";
import CollectionsTable from "./Collection/CollectionsTable";
import EditObject from "../../common/EditObject";
import EditableProductsTable from "./Products/EditableProductsTable";
import { updateMerchRoster } from "../../../API/roster";
import useCanUser from "../../../hooks/useCanUser";
import useNotification from "../../../hooks/useNotification";
import { getVendors } from "../../../API/hattrick";
import Transfer from "./TransferModal";
import { createTransfer, getTransfersByMerchId } from "../../../API/transferPortal";
import TransferProfile from "./TransferProfile";
import AthleteReferral from "./Referrals/AthleteReferral";

const AthleteProfile = ({ rosterId }) => {
  const [merchRoster, setMerchRoster] = useState();
  const [transfers, setTransfers] = useState([]);
  const [athleteEditModal, setAthleteEditModal] = useState(false);
  const [studentAthlete, setStudentAthlete] = useState();
  const [athleteUser, setAthleteUser] = useState();
  const [instagramData, setInstagramData] = useState();
  const [collections, setCollections] = useState();
  const [products, setProducts] = useState();
  const [vendors, setVendors] = useState();
  const [referrals, setReferral] = useState([]);

  const [loadingMerchRoster, setLoadingMerchRoster] = useState(false);
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [loadingCollections, setLoadingCollections] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingReferral, setLoadingReferral] = useState(false);

  const [reload, setReload] = useState(0);
  const [confirmProductDeletion, setConfirmProductDeletion] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [sportOptions, setSportOptions] = useState();

  const { actions } = usePermissionContext();
  const canUserAddedOnSite = useCanUser(actions.ALTER, [actions.ADDEDONSITE]);
  const canUserMarkOnSocial = useCanUser(actions.ALTER, [actions.POSTEDONSOCIAL]);
  const canUserChangeName = useCanUser(actions.ALTER, [actions.FIRSTNAME, actions.LASTNAME, actions.FULLNAME]);
  const canUserChangeSchool = useCanUser(actions.ALTER, [actions.SCHOOL]);
  const canUserChangeJerseyNumber = useCanUser(actions.ALTER, [actions.JERSEYNUMBER]);
  const canUserChangeSport = useCanUser(actions.ALTER, [actions.SPORT]);

  const user = useUserContext();
  const notify = useNotification();
  const selectOptions = useSchoolContext().schools.map((u) => u.schoolName);

  const editConfig = [
    {
      label: "Signed",
      type: "checkbox",
      field: "signed",
      style: { xs: 12, lg: 6 },
      can: canUserChangeJerseyNumber,
    },
    {
      label: "International",
      type: "checkbox",
      field: "is_international",
      style: { xs: 12, lg: 6 },
      can: canUserChangeJerseyNumber,
    },
    {
      label: "Added On Site",
      type: "checkbox",
      field: "addedOnSite",
      style: { xs: 12, lg: 6 },
      can: canUserAddedOnSite,
    },
    {
      label: "Posted On Social",
      type: "checkbox",
      field: "postedOnSocial",
      style: { xs: 12, lg: 6 },
      can: canUserMarkOnSocial,
    },
    {
      label: "Full Name",
      type: "text",
      field: "full_name",
      style: { xs: 12, lg: 12 },
      can: false,
    },
    {
      label: "First Name",
      type: "text",
      field: "first_name",
      style: { xs: 12, lg: 12 },
      can: canUserChangeName,
    },
    {
      label: "Last Name",
      type: "text",
      field: "last_name",
      style: { xs: 12, lg: 12 },
      can: canUserChangeName,
    },
    {
      label: "Jersey Number",
      type: "text",
      field: "jersey_number",
      can: canUserChangeJerseyNumber,
      validation: (val) => Number.isInteger(val),
      style: { xs: 12, lg: 12 },
    },
    {
      label: "Sport",
      type: "select",
      field: "sport",
      can: canUserChangeSport,
      style: { xs: 12, lg: 12 },
      selectOptions: sportOptions,
    },
    {
      label: "University",
      type: "select",
      field: "university",
      can: merchRoster && merchRoster.is_matched ? false : canUserChangeSchool,
      style: { xs: 12, lg: 12 },
      selectOptions: selectOptions,
    },
    {
      "label": "Venmo",
      type: "text",
      can: canUserChangeName,
      style: { xs: 6, lg: 6 },
      field: ["user", "venmoUserName"],
      isValid: (value) => {
        if (!value) {
          return true
        }
        const phoneNumberPattern = /^\d{1,3}\d{10,14}$/;
        if (!phoneNumberPattern.test(value) && value.length != 0) {
          return "The phone number must be in the following format: 18053909939"
        }
      }
    },
    {
      "label": "PayPal Email",
      type: "text",
      can: canUserChangeName,
      style: { xs: 6, lg: 6 },
      field: ["user", "payPalEmail"],
      isValid: (value) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value) && value.length != 0) {
          return "Please enter a valid email address"
        }
      }
    },
    {
      "label": "Instagram Handle",
      type: "text",
      can: canUserChangeName,
      style: { xs: 12, lg: 6 },
      field: ["instagram", "instagramUsername"],
    },
    {
      label: "Do you want to Delete all the Products associated to the Player?",
      type: "checkbox",
      field: "confirmDeletion",
      can: true,
      style: { xs: 12, lg: 12 },
    },
  ];

  const splitButtonOptions = [
    {
      text: "Edit",
      key: "Edit",
      disabled: false,
      onClick: () => setAthleteEditModal(true),
    },
    {
      text: "Transfer",
      key: "Transfer",
      disabled:
        !(merchRoster && merchRoster.is_matched) &&
        !(merchRoster && merchRoster.is_transfer),
      onClick: () => setTransferModal(true),
    },
  ];

  useEffect(() => {
    if (!user) {
      return;
    }
    setLoadingCollections(true);
    setLoadingProducts(true);
    getCollectionAndProductsByRosterId(rosterId, user)
      .then((response) => {
        console.log("collection got:", response.data.collections);
        setCollections(response.data.collections);
        setProducts(response.data.products);
        setLoadingCollections(false);
        setLoadingProducts(false);
      })
      .catch((error) => {
        console.error(error);
        notify("Failed to get products and collections");
        setLoadingCollections(false);
        setLoadingProducts(false);
      });

    getVendors(user)
      .then((response) => {
        //console.log(response.data);
        setVendors(response.data.obj);
      })
      .catch((error) => {
        console.error(error);
        notify("Failed to get Vendors");
      });
  }, [rosterId, reload, user, notify]);

  useEffect(() => {
    if (!user) {
      return;
    }
    setLoadingMerchRoster(true)

    getAthleteUserById(rosterId, user)
      .then(response => {
        console.log("respponse", response)
        setMerchRoster(response.data)
        setStudentAthlete(response.data?.StudentAthlete)
        setAthleteUser(response.data?.StudentAthlete?.User)
        setInstagramData(response.data?.StudentAthlete?.User?.InstagramDatum)

        setLoadingMerchRoster(false)
      }).catch(e => {
        notify("Failed to get athete profile")
      })

  }, [rosterId, user, reload, notify]);

  useEffect(() => {
    if (merchRoster?.university) {
      getAllSportsBySchool(merchRoster.university, user)
        .then(response => {
          setSportOptions(response.data)
        }).catch(e => {
          notify("Failed to get sport options for school")
        })
    }

    if (studentAthlete) {
      setLoadingReferral(true)
      getAllReferralByPhone(studentAthlete.phone, user)
        .then((response) => {
          console.log('referral response data: ', response);
          setLoadingReferral(false)
          setReferral(response.data.obj)
        }).catch(e => {
          setLoadingReferral(false)
          console.error("error fetching referral: ", e);
          notify("Failed to get athlete's referral.")
        })
    }

    setLoadingTransfer(true)
    getTransfersByMerchId(user, { idAthleteMerchInfo: rosterId })
      .then(response => {
        setLoadingTransfer(false)
        setTransfers(response.data)
      }).catch(e => {
        setLoadingTransfer(false)
        notify("Failed to get transfer records")
      })
  }, [merchRoster, user])

  const confirmTransfer = (reason, newSchool) => {
    // console.log("Confirm transfer", newSchool, merchRoster, reason);
    setTransferModal(false);
    if (merchRoster.university === newSchool && reason === "Transfer") {
      notify("Please select a new school for transfer");
    } else {
      setLoadingUpdate(true);
      createTransfer(user, merchRoster.idAthleteMerchInfo, newSchool, reason)
        .then((response) => {
          // console.log('Successful transfer initiated',response);
          notify({ msg: response.data.Response, variant: "success" });
          setReload((p) => p + 1);
          setTransferModal(false);
          setLoadingUpdate(false);
        })
        .catch((error) => {
          // console.log(error);
          notify("Failed to confirm transfer");
          setReload((p) => p + 1);
          setTransferModal(false);
          setLoadingUpdate(false);
        });
    }
  };

  const saveChanges = (altered) => {
    console.log("in save", altered, { athleteUser, merchRoster, studentAthlete });
    setLoadingUpdate(true);
    if (altered["instagram.Username"] != instagramData.instagramUsername) {
      updateUser({
        userId: studentAthlete.user_id,
        instagramHandle: altered["instagram.instagramUsername"],
        venmoPhoneNumber: altered["user.venmoUserName"] ? altered["user.venmoUserName"] : athleteUser.venmoUserName,
        payPalEmail: altered["user.payPalEmail"] ? altered["user.payPalEmail"] : athleteUser.payPalEmail
      }, user)
        .then((response) => {
          setReload(p => p + 1)
        }).catch(e => {
          notify("Failed to update instagram Handle")
        })
    }


    if (altered["user.venmoUserName"] != user.venmoUserName || altered["user.payPalEmail"] != user.payPalEmail) {
      //console.log("triggered change", { userId: studentAthlete.user_id, venmoPhoneNumber: altered["user.venmoUserName"] ? altered["user.venmoUserName"] : athleteUser.venmoUserName, payPalEmail: altered["user.payPalEmail"] ? altered["user.payPalEmail"] : athleteUser.payPalEmail })
      updateUser({ userId: studentAthlete.user_id, venmoPhoneNumber: altered["user.venmoUserName"] ? altered["user.venmoUserName"] : athleteUser.venmoUserName, payPalEmail: altered["user.payPalEmail"] ? altered["user.payPalEmail"] : athleteUser.payPalEmail }, user)
        .then(response => {
          setReload(p => p + 1)
          setLoadingUpdate(false);
        }).catch(e => {
          notify("Failed to update venmo phone number")
          setLoadingUpdate(false)
          setReload(p => p + 1)
        })
    }

    updateMerchRoster(
      {
        idAthleteMerchInfo: merchRoster.idAthleteMerchInfo,
        athlete_id: merchRoster.athlete_id,
        jersey_number: merchRoster.jersey_number,
        addedOnSite: merchRoster.addedOnSite,
        postedOnSocial: merchRoster.postedOnSocial,
        full_name: merchRoster.first_name + " " + merchRoster.last_name,
        first_name: merchRoster.first_name,
        last_name: merchRoster.last_name,
        signed: merchRoster.signed,
        is_international: merchRoster.is_international,
        ...altered,
      },
      user,
      confirmProductDeletion
    )
      .then((response) => {
        setReload((p) => p + 1);
        setLoadingUpdate(false);
      })
      .catch((e) => {
        //console.log(e);
        notify("Failed to update athlete");
        setReload((p) => p + 1);
        setLoadingUpdate(false);
      });
  };

  if (!merchRoster && !loadingMerchRoster) {
    return <>No athlete found</>;
  }

  if (!merchRoster) {
    return <></>;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          direction: "column",
          flexDirection: "column",
        }}
      >
        {loadingMerchRoster && loadingUpdate && <LinearProgress />}
        <ProfileHeader
          options={splitButtonOptions}
          name={merchRoster.full_name}
          instagramHandle={instagramData}
          school={merchRoster.university}
          sport={merchRoster.sport}
          jerseyNumber={merchRoster.jersey_number}
          profilePicture={user?.profilePicture}
          walletBalance={studentAthlete?.wallet_balance}
          phone={studentAthlete?.phone}
          email={athleteUser?.email}
          preferredPaymentMethod={athleteUser?.preferredPaymentMethod}
          hometown={merchRoster?.hometown}
          gender={athleteUser?.gender}
          isInternational={merchRoster.is_international}
          signed={merchRoster.signed}
          transfer={merchRoster.is_transfer}
          addedOnSite={merchRoster.addedOnSite}
          collectionHandle={
            collections && collections[0] ? collections[0].handle : ""
          }
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "flex-end",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            alignItems: "flex-end",
          }}
        >
          <Typography variant="caption">Wallet Balance</Typography>
          <div style={{ fontSize: "24px" }}>
            {"$" +
              (studentAthlete ? studentAthlete?.wallet_balance : "0.00")}
          </div>
        </div>

        <div>
          {loadingReferral && <LinearProgress />}
          <AthleteReferral
            referrals={referrals}
            referrerPlayerEmail={athleteUser?.email}
          />
        </div>

        <div>
          {loadingTransfer && <LinearProgress />}
          <TransferProfile transfers={transfers} />
        </div>

        <div>
          {
            <CollectionsTable
              collections={collections}
              loading={loadingCollections}
              refresh={() => setReload((p) => p + 1)}
              reload={reload}
            />
          }
        </div>
        <div>
          <EditableProductsTable
            products={products}
            vendors={vendors}
            merchRoster={merchRoster}
            loading={loadingProducts}
            refresh={() => setReload((p) => p + 1)}
            setLoading={setLoadingProducts}
          />
        </div>
      </div>
      <EditObject
        save={(altered) => {
          //console.log("saving", altered);
          saveChanges(altered);
          setAthleteEditModal(false);
        }}
        cancel={() => {
          setConfirmProductDeletion(false);
          setAthleteEditModal(false);
        }}
        open={athleteEditModal}
        config={editConfig}
        object={{ ...merchRoster, user: athleteUser, athlete: studentAthlete, instagram: instagramData }}
        style={{
          backgroundColor: "background.default",
          margin: "auto",
          marginTop: "10vh",
          padding: "20px",
          width: "60vw",
          borderRadius: "5px",
        }}
        confirmProductDeletion={confirmProductDeletion}
        setConfirmProductDeletion={setConfirmProductDeletion}
      />
      <Transfer
        style={{
          backgroundColor: "background.default",
          margin: "auto",
          marginTop: "10vh",
          padding: "20px",
          width: "40vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "5px",
        }}
        schoolOptions={selectOptions}
        object={merchRoster}
        open={transferModal}
        confirmTransfer={(reason, school) => {
          confirmTransfer(reason, school);
        }}
        close={() => {
          setTransferModal(false);
        }}
      />
    </>
  );
};

export default AthleteProfile;
