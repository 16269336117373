import { useEffect, useState } from "react";
import { CircularProgress, TableRow, Typography, TableCell } from "@mui/material";
import MUIDataTable from "mui-datatables";
import {
  useSchoolContext,
  useUserContext,
} from "../../../contexts/ProviderProvider.jsx";
import { useNavigate } from "react-router-dom";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox";
import NewSchool from "./NewSchool.jsx";
import { getSchools, updateSchool } from "../../../API/school";
import { revokeLicense } from "../../../API/hattrick.js";
import { uploadLicense, uploadSchoolIcon } from "../../../API/filesystem.js";
import useNotification from "../../../hooks/useNotification.jsx";
import LicenseInteractor from "./LicenseInteractor.jsx";
import { EditableModal } from "../../common/EditableModal.jsx";
import { convertSortedIndexNumber } from "../../../utilities/utils.js";

const Schools = () => {
  const [newSchool, setNewSchool] = useState(0);
  const [refresh, setRefresh] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [tableUpdates, setTableUpdates] = useState({});
  const [selectedRowData, setSelectedRowData] = useState({});

  const navigate = useNavigate();
  const user = useUserContext();
  const notify = useNotification();

  const { schools, setSchools } = useSchoolContext();

  useEffect(() => {
    if (!user) return;
    getSchools(user)
      .then((response) => {
        // add license_royalty_percentage which is license_royalty * 100
        response.data.schools.forEach((school) => {
          school.license_royalty_percentage = school.license_royalty * 100;
        });
        setSchools(response.data.schools);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [setSchools, user, newSchool, refresh]);

  const getColorStyles = (colors) => {


    colors = colors.map((color) => {
      if (color.length > 0 && color[0] !== "#") {
        return "#" + color;
      }
      return color;
    })

    if (colors.length === 1) {
      return { color: colors[0] };
    } else if (colors.length === 2) {
      return {
        background: `linear-gradient(to right, ${colors[0]} 50%, ${colors[1]} 50%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
      };
    } else if (colors.length === 3) {
      return {
        background: `linear-gradient(to right, ${colors[0]} 33%, ${colors[1]} 33%, ${colors[1]} 66%, ${colors[2]} 66%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
      };
    }
    return {};
  };

  const openModal = (index) => {
    setSelectedIndex(index)
    setSelectedRowData(schools[index])
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setSelectedIndex(-1)
    setSelectedRowData({})
    setIsModalOpen(false)
  }

  const getBackgroundColorStyles = (colors) => {


    colors = colors.map((color) => {
      if (color.length > 0 && color[0] !== "#") {
        return "#" + color;
      }
      return color;
    })

    if (colors.length === 1) {
      return { background: colors[0] };
    } else if (colors.length === 2) {
      return {
        background: `linear-gradient(to right, ${colors[0]} 50%, ${colors[1]} 50%)`,

      };
    } else if (colors.length === 3) {
      return {
        background: `linear-gradient(to right, ${colors[0]} 33%, ${colors[1]} 33%, ${colors[1]} 66%, ${colors[2]} 66%)`,

      };
    }
    return {};
  };

  const SchoolColorRowRender = ({ dataIndex, rowData, rowMeta }) => {
    const colors = schools[rowMeta].schoolColors ? schools[rowMeta].schoolColors.split(",") : []
    const colorStyles = getBackgroundColorStyles(colors);

    return (
      <TableRow style={colorStyles}>
        {rowData.map((cellData, cellIndex) => (
          <TableCell key={cellIndex}>{cellData}</TableCell>
        ))}
      </TableRow>
    );
  };

  const editOptions = {
    "division": { label: "Division", "type": "Text" },
    "subDivision": { label: "Sub Division", "type": "Text" },
    "conference": { label: "Conference", "type": "Text" },
    "url": { label: "School Roster URL", "type": "Text" },
    "license_royalty": { label: "School License Royalty", "type": "Skip" },
    "license_royalty_percentage": { label: "School License Royalty Percentage", "type": "Text", "endAdornment": "%" },
    "schoolColors": { label: "School's Colors", "type": "Text" },
    "licensing_company": { label: "License Company", "type": "Text", disabled: true },
    "school_icon": { label: "School Icon", "type": "Img" }
  };

  const handleUpdate = () => {

    const rowChanges = tableUpdates[selectedIndex]

    if (rowChanges["license_royalty_percentage"]) {
      var royalty_rate = parseInt(rowChanges["license_royalty_percentage"], 10);

      // Check if the conversion was successful
      if (royalty_rate) {
        royalty_rate /= 100
      } else {
        return
      }
    }

    const promises = [];
    Object.keys(rowChanges).map(key => {
      var value = rowChanges[key]
      if (key == "license_royalty_percentage") {
        promises.push(updateSchool(selectedRowData.schoolName, "license_royalty", royalty_rate, user))
      } else { promises.push(updateSchool(selectedRowData.schoolName, key, value, user)) }

    });

    Promise.all(promises)
      .then((response) => {
        notify({ msg: "Success", variant: "success" });
        setTableUpdates((prevTableUpdates) => {
          const updatedTableUpdates = { ...prevTableUpdates };
          updatedTableUpdates[selectedIndex] = {};
          return updatedTableUpdates;
        });
        setIsModalOpen(false);
        setSelectedIndex(-1);
        setSelectedRowData({});
        setRefresh((p) => p + 1);

      })
      .catch((err) => {
        notify("Failed to upload license");
      });

  }


  const uploadLicenses = (
    school,
    accountManagerId,
    employeeId,
    file,
    callback,
    url,
    royaltyRate,
    licensingCompany,
    schoolIcon,
    schoolColors,
    division,
    subDivision,
    conference
  ) => {
    url = url.replace(/\.(.*?)\/.*/, '.$1/')
    var updated_royalty_rate = parseInt(royaltyRate, 10);

    // Check if the conversion was successful
    if (updated_royalty_rate) {
      updated_royalty_rate /= 100
    } else {
      return
    }
    const promises = [];

    // promises for updating school table columns
    promises.push(updateSchool(school, 'division', division, user))
    promises.push(updateSchool(school, 'subDivision', subDivision, user))
    promises.push(updateSchool(school, 'conference', conference, user))

    promises.push(updateSchool(school, 'url', url, user))

    promises.push(updateSchool(school, 'license_royalty', updated_royalty_rate, user))
    promises.push(updateSchool(school, 'schoolColors', schoolColors, user))
    promises.push(updateSchool(school, 'licensing_company', licensingCompany, user))

    // Upload Files and update School table
    promises.push(uploadLicense(file, { school, accountManagerId, employeeId }, user))
    promises.push(uploadSchoolIcon(schoolIcon, { school }, user))

    Promise.all(promises)
      .then((response) => {
        notify({ msg: "Success", variant: "success" });
        callback();
        setRefresh((p) => p + 1);
      })
      .catch((err) => {
        notify("Failed to upload license");
        callback();
        setRefresh((p) => p + 1);
      });
  };

  const revokeSchoolLicense = (schoolName) => {
    console.log("revoke:", schoolName);
    revokeLicense(schoolName, user)
      .then((result) => {
        notify({ msg: "Success", variant: "success" });
        setRefresh((p) => p + 1);
      })
      .catch((e) => {
        notify("Failed: " + e);
      });
  };

  const columns = [
    {
      label: "Name",
      name: "schoolName",
      // options: {
      //   customBodyRender: (value, tableMeta) => {
      //     const colors = schools[convertSortedIndexNumber(tableMeta)].schoolColors ? schools[convertSortedIndexNumber(tableMeta)].schoolColors.split(",") : []

      //     const colorStyles = getColorStyles(colors);

      //     return <span style={colorStyles}>{value}</span>;
      //   }
      // }
    },
    {
      label: "License Status",
      name: "licenseStatus",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TrueFalseCheckbox checked={value === "Approved"} disabled={true} />
          );
        },
        sortCompare: (order) => {
          return (a, b) => {
            const obj1 = a.data;
            const obj2 = b.data;
            //console.log(obj1, obj2);
            let val1 = obj1 === undefined || obj1 === null ? 0 : obj1 ? 1 : 0;
            let val2 = obj2 === undefined || obj2 === null ? 0 : obj2 ? 1 : 0;
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      label: "License Provider",
      name: "licensing_company",
    },
    {
      label: "Allow International Athletes",
      name: "allowInternational",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <TrueFalseCheckbox checked={value} disabled={true} />;
        },
        sortCompare: (order) => {
          return (a, b) => {
            const obj1 = a.data;
            const obj2 = b.data;
            let val1 = obj1 === undefined || obj1 === null ? 0 : obj1 ? 1 : 0;
            let val2 = obj2 === undefined || obj2 === null ? 0 : obj2 ? 1 : 0;
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      label: "License Upload",
      name: "license",
      options: {
        display: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <LicenseInteractor
              value={value}
              tableMeta={tableMeta}
              updateValue={updateValue}
              revokeLicense={revokeSchoolLicense}
              uploadLicenses={uploadLicenses}
              openModal={openModal}
              schoolData={schools[convertSortedIndexNumber(tableMeta)]}
            />
          );
        },
      },
    },
  ];

  return (
    <div style={{ width: "80%" }}>
      <Typography variant="h1">Schools</Typography>
      <NewSchool reload={() => setNewSchool((prev) => prev + 1)} />
      <MUIDataTable
        style={{ width: "100%" }}
        data={schools}
        options={{
          onCellClick: (colData, cellMeta) => {
            if (cellMeta.colIndex === 0) {
              navigate("/ballpark/schools/" + btoa(colData));
            }
          },
          rowsPerPage: window.rowsPerPage,
          textLabels: {
            body: {
              noMatch: <CircularProgress />,
            },
          },
          selectableRows: "none",
          // customRowRender: (data, dataIndex, rowIndex) => {
          //   return <SchoolColorRowRender rowData={data} dataIndex={dataIndex} rowMeta={rowIndex} />;
          // }
        }}
        columns={columns}
      />
      <EditableModal
        open={isModalOpen}
        options={editOptions}
        data={selectedRowData}
        title={"Edit School Modal"}
        selectedIndex={selectedIndex}
        editedData={tableUpdates}
        setEditedData={setTableUpdates}
        saveChanges={handleUpdate}
        handleModalClose={closeModal}
        isEditable={true}
      />
    </div>
  );
};

export default Schools;
