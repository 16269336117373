import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { runMarathonCSVInfluxer } from '../../../API/marathon';

import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";

const MarathonCSVUploader = () => {
    const [csvFile, setCsvFile] = useState(null);

    const user = useUserContext();
    const notify = useNotification();

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setCsvFile(acceptedFiles[0]);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.csv',
        maxFiles: 1,
    });

    const handleRun = async () => {
        if (!csvFile) return;

        runMarathonCSVInfluxer(csvFile, user).then((res) => {
            notify({ msg: `Started marathon for csv file`, variant: 'success' });
        }).catch((err) => {
            console.log(err);
            notify("Failed to start Marathon: " + err.message)

        });
    };

    const handleReset = () => {
        setCsvFile(null);
    };

    return (
        <div style={{ width: "80%", paddingTop: "5vh" }}>
            <div style={{ width: "50%", margin: "0 auto" }}>
                <Typography variant="h1">Marathon</Typography>
                <Box sx={{ width: '100%', maxWidth: 500, margin: '0 auto', textAlign: 'center' }}>
                    <Box
                        {...getRootProps()}
                        sx={{
                            border: '2px dashed #56BC6C',
                            borderRadius: '8px',
                            padding: '20px',
                            cursor: 'pointer',
                        }}
                    >
                        <input {...getInputProps()} />
                        <Typography variant="body1" >
                            {csvFile ? `Uploaded: ${csvFile.name}` : 'Drag & drop a CSV file here, or click to select one'}
                        </Typography>
                    </Box>

                    {csvFile && (
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: '#56BC6C', color: 'white' }}
                                onClick={handleRun}
                            >
                                Run
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{ borderColor: '#56BC6C', color: '#56BC6C' }}
                                onClick={handleReset}
                            >
                                Reset
                            </Button>
                        </Box>
                    )}
                </Box>
            </div>
        </div>
    );
};

export default MarathonCSVUploader;
