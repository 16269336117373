import axios from "axios";

const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;
const HATTRICK_URL = process.env.REACT_APP_HATTRICK_URL;

export const createProducts = (payload, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.post(BALLPARK_BACKEND_BASE_URL + "createNewProducts", payload, {
    headers,
  });
};
export const getDistinctProductsForSchool = (school, sport, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BALLPARK_BACKEND_BASE_URL +
    "getDistinctProductsForSchool?uni=" +
    btoa(school) +
    "&sport=" +
    btoa(sport),
    {
      headers,
    }
  );
};

export const getUniversityProductCount = (user, axiosOptions) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(HATTRICK_URL + "ballpark/getCountByProductsNotCreated", { ...axiosOptions, headers });
}


export const getUniversityAssetCount = (user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(BALLPARK_BACKEND_BASE_URL + "getCountByAssetsNotCreated", { headers });
}

export const getSportList = (user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(HATTRICK_URL+ "products/genderedSportList", { headers });
}
