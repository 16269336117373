import axios from "axios";

const BASE_URL = process.env.REACT_APP_HATTRICK_URL;

function decodePath(inputString) {
  let decodedString = decodeURIComponent(inputString);
  let step1 = decodedString.replace(/\?preview=/, '/');
  let step2 = step1.replace(/\+/g, ' ');
  return step2;
}

export async function verifyPath(path) {
  let modifiedPath = decodePath(path);
  let pathDict = { path: modifiedPath };
  return axios
    .post(BASE_URL + "marathon/checkpath", pathDict)
    .then((response) => {
      return { success: true };
    })
    .catch((error) => {
      console.log("error:", error);
      return { success: false, error: "Path not found" };
    });

}

export async function startMarathon(path, side, user) {
  let modifiedPath = decodePath(path);
  let marathonDict = { path: modifiedPath, local: false, side: side };
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };

  return axios
    .post(BASE_URL + "marathon/run", marathonDict, { headers })
    .then((response) => {

      return { Success: true };
    })
    .catch((error) => {
      if (error.response.data.includes("already running"))
        return { success: false, errorText: "Marathon is currently running" };
      else if (error.response.data.includes("File not found"))
        return { success: false, errorText: "File not found in the path" };
      else return { success: false };
    });
}

export async function startMarathonInfluxer(marathonRequests, user) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios
    .post(BASE_URL + "marathon/run", marathonRequests, { headers })
    .then((response) => {
      return { Success: true };
    })
    .catch((error) => {
      return { success: false };
    });
}

export async function runMarathonCSVInfluxer(csvFile, user) {

  let headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: "Bearer " + user.accessToken,
  };

  const formData = new FormData();
  formData.append('file', csvFile);

  return axios.post(BASE_URL + 'marathon/runCSV', formData, {
    headers: headers,
  });


}
