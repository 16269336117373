import {
  TextField,
  Autocomplete,
  Button,
  Chip
} from "@mui/material";
import { Fragment } from "react";

const MultiSelect = ({ value, inputValue, options, onChange, onInputChange, label, allButton, between, ...props }) => {
  return (
    <Autocomplete
      value={value}
      multiple
      clearOnEscape
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={onInputChange ? (event, newInputValue) => {
        onInputChange(newInputValue);
      } : undefined}
      options={options}
      getOptionLabel={(option) => option}
      style={props.style ? props.style : { width: "90%", margin: "10px 10px", }}
      disabled={props.disabled}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Fragment key={option}>
            {index > 0 && <span style={{ margin: "0 8px" }}>{between}</span>}
            <Chip
              label={option}
              {...getTagProps({ index })}
            />
          </Fragment>
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          style={{ width: "100%" }}
          InputProps={{
            ...params.InputProps,
            endAdornment: allButton ? (
              <>
                <Button
                  disabled={value.includes("All")}
                  variant="contained"
                  onClick={() => {
                    onChange(["All"]);
                    onInputChange ? onInputChange("") : (() => { })();
                  }}
                >
                  All
                </Button>
                {params.InputProps.endAdornment}
              </>
            ) : null,
          }}
          type="text"
        />
      )}
    />);
}

export default MultiSelect
