import { ButtonGroup, Button, CircularProgress } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { sendReReferralByEmail } from "../../../../API/roster";
import { useUserContext } from "../../../../contexts/ProviderProvider";
import useNotification from "../../../../hooks/useNotification";
import AreYouSure from "../../../common/AreYouSure";

const AthleteReferralActions = ({ tableMeta, referrerPlayerEmail }) => {

    const referredPLayerFullName = tableMeta.rowData[0];

    const user = useUserContext();
    const notify = useNotification();

    const [loading, setLoading] = useState(false);

    const [confirmType, setConfirmType] = useState();
    const [confirmReferredPLayerName, setConfirmReferredPLayerName] = useState();

    const confirmAreYouSure = (type, referredPLayerFullName) => {
        setConfirmType(type);
        console.log("referredPLayerFullName:- ", referredPLayerFullName)
        setConfirmReferredPLayerName(referredPLayerFullName);
    };

    const nullifyState = () => {
        setConfirmType(undefined);
        setConfirmReferredPLayerName(undefined);
    };

    const submitAreYouSure = () => {
        setLoading(true);
        sendReReferralByEmail(referrerPlayerEmail, confirmReferredPLayerName, user)
            .then((response) => {
                console.log("response from re-referral by email: ", response);
                notify({ msg: response.data?.obj, variant: "success" });
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error sending referral: ", error);
                notify("Failed to send re-referral");
                setLoading(false);
            });
    }

    return (
        <>
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <ButtonGroup variant="contained">
                        {referredPLayerFullName && referrerPlayerEmail ? (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    confirmAreYouSure(
                                        "REFERRAL",
                                        referredPLayerFullName
                                    );
                                }}
                            >
                                Resend Referral
                            </Button>
                        ) : (
                            "N/A"
                        )}
                    </ButtonGroup>
                    <AreYouSure
                        style={{
                            backgroundColor: "background.default",
                            margin: "auto",
                            marginTop: "10vh",
                            padding: "20px",
                            width: "30vw",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            borderRadius: "5px",
                        }}
                        open={confirmType ? true : false}
                        message={'Are you sure you want re send referral?'}
                        handleClose={() => nullifyState()}
                        yes={() => {
                            submitAreYouSure();
                            nullifyState();
                        }}
                        no={() => nullifyState()}
                    />
                </>
            )}
        </>
    );
};

export default AthleteReferralActions;
