import * as React from "react";
import Can from "../../../common/Can";
import { usePermissionContext } from "../../../../contexts/ProviderProvider";
import { Accordion, AccordionSummary, CircularProgress, Typography } from "@mui/material";
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Navigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import AthleteReferralActions from "./AthleteReferralAction";

// Re-Referral for Athletes Page for individual referral
const AthleteReferral = ({ referrals, referrerPlayerEmail }) => {

    const { views } = usePermissionContext();

    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {

    }, [referrals]);

    const columns = [
        {
            name: "full_name",
            label: "Name",
        },
        {
            name: "university",
            label: "University",
        },
        {
            name: "sport",
            label: "Sport",
        },
        {
            name: "email",
            label: "Email",
        },
        {
            label: "Action",
            name: "action",
            options: {
                empty: true,
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <AthleteReferralActions
                            tableMeta={tableMeta}
                            referrerPlayerEmail={referrerPlayerEmail}
                        />
                    );
                },
            },
        },
    ];

    const options = {
        selectableRows: 'none',
        textLabels: {
            body: {
                noMatch: referrals.length < 0 ?
                    <CircularProgress />
                    : "No Records"
            },
        },
    }

    return (
        <>

            <Accordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
                disabled={referrals.length > 0 ? false : true}
            >
                <AccordionSummary
                    expandIcon={referrals.length > 0 && <ArrowDownwardIcon />}
                    aria-controls="Referrals-content"
                    id="Referrals-header"
                >
                    <Typography>Referred {referrals?.length | 0}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Can action={views.VIEW} items={[views.REFERRAL]} >
                        <div
                            style={{
                                margin: "20px 10px"
                            }}>
                            {referrals ?
                                <>
                                    <MUIDataTable
                                        data={referrals}
                                        columns={columns}
                                        options={options}
                                    />
                                </>
                                :
                                <Typography variant="h6">No Referral Found</Typography>
                            }
                        </div>
                    </Can>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default AthleteReferral;
